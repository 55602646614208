
// import { update, detail, has } from "../../api/tag.js";
import { pinyin } from "pinyin-pro";
import { defineComponent } from "vue";
import { client } from '@/client';
import { ElMessage } from "element-plus";
export default defineComponent({
  name: "tag-edit",

  data: () => {
    return {
      params: {
        //接口入参
        id: 0,
        name: "",
        path: "",
      },

      paramsRules: {
        //校验规则
        name: [
          { required: true, message: "请输入标签名称", trigger: "blur" },
          {
            min: 2,
            max: 8,
            message: "名称长度在 2 到 8 个字符之间",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    "params.name": function (newv, oldv) {
      this.params.path = pinyin(newv, { toneType: "none" }).replace(/\s+/g, "");
    },
  },
  async created() {
    let id = this.$route.params.id as string;
    this.params.id = parseInt(id);
    await this.detail();
  },
  methods: {
    // 文章详情
    async detail() {
      try {
        let res = await client.callApi("tag/Detail",{id:this.params.id});
        if (res.isSucc) {
          this.params = {
            id:res.res.id,
            name:res.res.tag_name,
            path:res.res.tag_path
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    //新增
    async update() {
      try {
        let res = await client.callApi("tag/Edit",{
            id:this.params.id,
            tag_name:this.params.name,
            tag_path:this.params.path
          });
        if (res.isSucc) {
          ElMessage({
            message: "更新成功^_^",
            type: "success",
          });
          this.$router.go(-1);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async has() {
      try {
        let res = await client.callApi("tag/Has",this.params);
        // let res = await has(this.params.path);
        if (res.isSucc) {
            ElMessage({
              message: "标签已存在，修改无效",
              type: "success",
            });
        }else{
            this.update();
          }
      } catch (error) {
        console.log(error);
      }
    },
    submit(formName: string) {
      let refs:any = this.$refs[formName];

      refs.validate((valid: any) => {
        if (valid) {
          this.has();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
});
